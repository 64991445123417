<template>
  <div class="edit-div">
    <label for="name">{{ $t("label.name") }}</label>
    <input type="text" id="name" v-model="name" required />
    <br />
    <label for="surname">{{ $t("label.surname") }}</label>
    <input type="text" id="surname" v-model="surname" required />
    <br />
    <label for="email">{{ $t("label.email") }}</label>
    <input type="email" id="email" v-model="email" required /> <br />
    <label for="emailConfirmation">{{ $t("label.emailConfirmation") }}</label>
    <input
      type="email"
      id="emailConfirmation"
      v-model="emailConfirmation"
      required
    />
    <div class="error-message" v-if="emailCheck != ''">
      {{ emailCheck }}
    </div>
    <br v-if="emailCheck == ''" />
    Gender:
    <label for="male">{{ $t("label.male") }}</label>
    <input
      type="radio"
      id="male"
      v-model="gender"
      value="male"
      checked="checked"
    />
    <label for="female">{{ $t("label.female") }}</label>
    <input type="radio" id="female" v-model="gender" value="female" />
    <div v-if="newAgency">
      <br />
      <h2 class="section-tittle">Création nouvelle agence :</h2>
      <div class="newAgencyForm">
        <div class="formElement">
          <label for="agencyName">{{ $t("label.agencyName") }} :</label>
          <input type="text" id="agencyName" v-model="agencyName" required />
        </div>
        <div class="formElement">
          <label for="agencyEmail">{{ $t("label.agencyEmail") }} :</label>
          <input type="email" id="agencyEmail" v-model="agencyEmail" />
        </div>
        <div class="formElement">
          <label for="emergencyNumber"
            >{{ $t("label.emergencyNumber") }} :</label
          >
          <input type="email" id="emergencyNumber" v-model="emergencyNumber" />
        </div>
      </div>
      <h2 class="section-tittle">Offres promotionelles spécifiques :</h2>
      <div class="offers">
        <div class="offer">
          <div>
            <input
              type="checkbox"
              id="applicationFee"
              v-model="contractInfo.offers.applicationFee.state"
            />
            <label for="applicationFee">Frais de dossiers</label>
          </div>
          <div>
            <p>
              Nouveau montant des frais de dossiers : <br /><input
                id="applicationFeeInput"
                type="number"
                min="0"
                max="99"
                v-model="contractInfo.offers.applicationFee.price"
              />
              €
            </p>
          </div>
        </div>
        <div class="offer">
          <div>
            <input
              type="checkbox"
              id="cancelSubscriptionTravel"
              v-model="contractInfo.offers.cancelSubscriptionTravel.state"
            />
            <label for="cancelSubscriptionTravel"
              >Annulation abonnement nombre voyages</label
            >
          </div>
          <div>
            <p>
              Annulation des frais d'abonnement à partir de
              <input
                id="cancelSubscriptionTravelInput"
                type="number"
                min="0"
                v-model="contractInfo.offers.cancelSubscriptionTravel.number"
              />
              voyage dans le même mois
            </p>
          </div>
        </div>
        <div class="offer">
          <div>
            <input
              type="checkbox"
              id="cancelSubscription"
              v-model="contractInfo.offers.cancelSubscription.state"
            />
            <label for="cancelSubscription">Annulation abonnement date</label>
          </div>
          <div>
            <p>
              Annulation des frais d'abonnement jusqu'au
              <input
                id="cancelSubscriptionInput"
                type="date"
                v-model="contractInfo.offers.cancelSubscription.date"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />

    <button
      id="confirmation-button"
      @click="submitEdit"
      :disabled="checkForm"
      class="save-button"
    >
      {{ $t("buttons.save") }}
    </button>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "TourAgencyEmployeeForm",
  data() {
    return {
      name: "",
      surname: "",
      email: "",
      agencyName: "",
      agencyEmail: "",
      gender: "male",
      emergencyNumber: "",
      emailConfirmation: "",
      contractInfo: {
        offers: {
          applicationFee: {
            state: false,
            price: 99
          },
          cancelSubscriptionTravel: {
            state: false,
            number: 0
          },
          cancelSubscription: {
            state: false,
            date: Date.now()
          }
        }
      }
    };
  },
  props: {
    agency: { type: String, default: "" },
    newAgency: { type: Boolean, default: true },
  },
  computed: {
    checkForm: function () {
      if (!this.name) {
        return true;
      }
      if (!this.surname) {
        return true;
      }
      if (!this.email) {
        return true;
      }
      if (!this.agency && !this.emergencyNumber) {
        return true;
      }
      if (!this.agency && !this.agencyName && this.newAgency) {
        return true;
      }
      if (!this.agency && !this.agencyEmail && this.newAgency) {
        return true;
      }

      return false;
    },
    emailCheck: function () {
      if (this.email != this.emailConfirmation) {
        return this.$t("error.emailCheck");
      }
      return "";
    },
  },
  methods: {
    submitEdit: async function () {
      try {
        this.$store.dispatch("load", this.$t("loadMessage.registerEmployee"));

        let newData = {
          name: this.name,
          surname: this.surname,
          email: this.email,
          agencyEmail: this.agencyEmail,
          agencyName: this.agencyName,
          newAgency: this.newAgency,
          agency: this.agency,
          gender: this.gender,
          emergencyNumber: this.emergencyNumber,
        };
        if (this.contractInfo.offers.applicationFee.state) {
          newData["contractInfo.offers.applicationFee"] = {
            state: this.contractInfo.offers.applicationFee.state,
            price: this.contractInfo.offers.applicationFee.price
          };
        }
        if (this.contractInfo.offers.cancelSubscriptionTravel.state) {
          newData["contractInfo.offers.cancelSubscriptionTravel"] = {
            state: this.contractInfo.offers.cancelSubscriptionTravel.state,
            number: this.contractInfo.offers.cancelSubscriptionTravel.number
          };
        }
        if (this.contractInfo.offers.cancelSubscription.state) {
          newData["contractInfo.offers.cancelSubscription"] = {
            state: this.contractInfo.offers.cancelSubscription.state,
            date: this.contractInfo.offers.cancelSubscription.date
          };
        }
        let res = await HandleApi.postEmployee(newData);
        if (res.status == 200) {
          this.$store.dispatch("unload");
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.created", {
              object: this.$t("notification.employee"),
            })
          );
          if (this.agency) {
            this.$router.push("/agency/" + this.agency);
          } else {
            this.$router.push("/employee/" + res.data);
          }
        }
      } catch (err) {
        this.$store.dispatch("unload");
        this.$store.dispatch("push_error_message", err);
      }
    },
  },
};
</script>

<style scoped>
.edit-div {
  text-align: center;
  margin-top: 20px;
  line-height: 40px;
}

.edit-div label {
  margin-right: 10px;
  margin-left: 10px;
}

.error-message {
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  max-width: 90%;
  width: 500px;
  margin: 0 auto;
  border-radius: 10px;
}

.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.errorField {
  -moz-box-shadow: 0 0 3px red;
  -webkit-box-shadow: 0 0 3px red;
  box-shadow: 0 0 3px red;
}
.newAgencyForm {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-bottom: 40px;
}
.newAgencyForm .formElement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.section-tittle {
  font-size: 150%;
  font-weight: bold;
  margin-bottom: 10px;
}
.offers {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.offer {
  width: 25%;
  border: 1px black solid;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 800px) {
  .offers {
    flex-direction: column;
    align-items: center;
  }
  .offer {
    width: 75%;
    margin: 5px 0;
  }
}
.offer label {
  font-weight: bold;
}
#cancelSubscriptionTravelInput {
  width: 20%;
}
#applicationFee:checked + label,
#cancelSubscriptionTravel:checked + label,
#cancelSubscription:checked + label {
  font-size: 102%;
  color: #00909f;
}
</style>
